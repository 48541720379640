import React, {Component, useEffect} from "react"
import Hero from "../hero/hero"
import scrollerStyles from "./scroller.module.scss"
import { theme } from "../../../tailwind.config.js"
import _ from "lodash";
import ScrollIndicator from "../scroll-indicator/scroll-indicator";


type ScrollerProps = { 
};

type DistanceState = { distance: number | undefined };

export default class Scroller extends React.Component<ScrollerProps, DistanceState> {
  private contentRef: React.RefObject<HTMLElement>;
  private _isScrollingUp: boolean = false;

  constructor(props:any) {
    super(props);
    this.state = {
      distance: undefined,
    }
    this.contentRef = React.createRef();
  }

  shouldComponentUpdate(_nextProps: any, nextState: any) {  //Prevents rerender if state (distance) is unchanged
    if (this.state.distance !== nextState.distance) {
      return true;
    }
    return false;
  }

  componentDidMount(){
    window.addEventListener('scroll', _.throttle(this.handleScroll, 100));
    this.getDistance();
  };

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    this.getDistance();
  };

  getDistance = () => {
    //Get Distance
    if (!this.contentRef.current) {
      return;
    }
    let distancePercantage = this.contentRef.current.getBoundingClientRect().top / window.innerHeight - 1; //start off early
    if (distancePercantage < 0) {
      distancePercantage = 0;
    } else if (distancePercantage > 1) {
      distancePercantage = 1;
    } else {
      distancePercantage = Math.round( ( distancePercantage + Number.EPSILON ) * 1000 ) / 1000;
    }
    let currentDistance = !this.state.distance ? 0 : this.state.distance;
    if (distancePercantage !== this.state.distance) {
      //Get scroll direction
      this._isScrollingUp = currentDistance < distancePercantage; 
      //Set state <- this will update component
      this.setState({distance: distancePercantage});
    }
  }

  render() { 
    return (
      <>
        {/* Slot for top section */}
        <section className={scrollerStyles.topSection}>
          <div className={`absolute w-screen h-screen flex`} style={{display: `${!this.state.distance || this.state.distance < .1 ? 'none' : ''}`}}>
            <div className={`m-auto mb-16 z-10`}>
              <ScrollIndicator></ScrollIndicator>
            </div>
          </div>
          <Hero
            title={`Samuel Li`}
            subtitle={`UI/UX Designer & Front End Developer`}
            distance={this.state.distance}
            isScrollingUp={this._isScrollingUp}
          >
          </Hero>
        </section>
        {/* Slot for content */}
        <section className={scrollerStyles.content} ref={this.contentRef} style={{background: `linear-gradient(${theme.extend.colors.darkblue} 50%, ${theme.extend.colors.white2} 50%)`}}>
          {this.props.children}
        </section>
      </>
    );
  }
}