import React, {Component} from "react"
import style from "../../components/timeline/timeline.module.scss"

type TimelineProps = { 
  nodes: string
};

export default class Label extends React.Component<TimelineProps> {
    private _nodeObj = JSON.parse(this.props.nodes);
    
    private _nodeElements = this._nodeObj.nodes.map((node, i) =>
        <div key={i} className={`flex-1 text-sm ${style.node} ${style.connector}`}>
            { i === this._nodeObj.nodes.length - 1 && 
                <div className={`${style.nodeActive}`}></div>
            }
            <div className="font-bold mb-1">{node.title}</div>
            <div>{node.date}</div>
        </div>
    );
    
    render() {
        return (
        <div>
            <div className="nodes-container flex pt-2 relative">
                <div className="absolute background-primary h-4 w-full"></div>
                {this._nodeElements}
            </div>
        </div>
        );
    }
}