import React, {Key} from "react";
import { Link } from "gatsby";
import { MarkdownNode } from './interface/Markdownnode.interface';
import IphoneGraphic from "./iphone-graphic/iphone-graphic";
import IpadGraphic from "./ipad-graphic/ipad-graphic";
import Label from "./label/label";
import Button from "./button/button";


type PostProps = { 
  node: MarkdownNode,
  key?: Key;
};

export default class Post extends React.Component<PostProps> {
    private _previewPhone = this.props.node.frontmatter.previewPhone;
    private _previewTablet = this.props.node.frontmatter.previewTablet;
    private _previewDesktop = this.props.node.frontmatter.previewDesktop;
    private _previewVideo = this.props.node.frontmatter.previewVideo;

    render() {
        return (
            <article className={`${!this._previewPhone ? 'lg:flex' : ''} mb-32`}>
                <div className={`${this._previewDesktop || this._previewVideo ? 'md:mr-16' : ''}`}>
                    <Link
                        to={this.props.node.fields.slug}
                    >
                        <h3 className={`text-2xl hover:underline font-serif font-semibold mb-2`}>
                            {this.props.node.frontmatter.title}
                        </h3>
                    </Link>
                    { this.props.node.frontmatter.subtitle && <div className="text-xs uppercase font-bold">{this.props.node.frontmatter.subtitle}</div> }
                    <div className={`mb-2 mt-6`}>
                        { this.props.node.frontmatter.tagsDev && this.props.node.frontmatter.tagsDev.map((value, index) => {
                            return <Label appearance="primary" key={index}>{value}</Label>
                        })}
                    </div>
                    <div>
                        { this.props.node.frontmatter.tagsUI && this.props.node.frontmatter.tagsUI.map((value, index) => {
                            return <Label appearance="secondary" key={index}>{value}</Label>
                        })}
                    </div>
                    
                    <p className={`my-8`}>{this.props.node.frontmatter.summary ? this.props.node.frontmatter.summary : this.props.node.excerpt }</p>
                    <div className={`mb-8 flex whitespace-nowrap`}>
                        <div className={`mr-4 mb-4`}>
                                <Button 
                                    text="View case study"
                                    appearance="primary"
                                    url={this.props.node.fields.slug}
                                >
                                </Button>
                            
                        </div>
                        <div>
                            { !this.props.node.frontmatter.codeUrl &&
                                <Button 
                                    text="View live"
                                    disabled={this.props.node.frontmatter.url === null || this.props.node.frontmatter.url === undefined}
                                    url={this.props.node.frontmatter.url}
                                    target="_blank"
                                    appearance="secondary"
                                >
                                </Button>
                            }
                            { this.props.node.frontmatter.codeUrl &&
                                <Button 
                                    text="View code"
                                    url={this.props.node.frontmatter.codeUrl}
                                    target="_blank"
                                    appearance="tertiary"
                                >
                            </Button>
                            }
                        </div>
                    </div>
                </div>
                
                <div className={`relative`} style={this._previewPhone && !this._previewDesktop ? {paddingBottom:'700px', perspective:'0px', zIndex: 1} : {}}>
                    {this._previewTablet && 
                        <IpadGraphic interactive>
                            <img 
                                src={this._previewTablet} 
                                alt={`${this.props.node.frontmatter.title} tablet screenshot`} 
                                style={{width:'100%'}}/>
                        </IpadGraphic>
                    }
                    { this._previewPhone && 
                        <IphoneGraphic interactive>
                            <img 
                                src={this._previewPhone} 
                                alt={`${this.props.node.frontmatter.title} phone screenshot`}
                                style={{width:'100%'}} />
                        </IphoneGraphic>
                    }

                    { this._previewDesktop !== null && 
                        <div className={`shadow`}>
                            <img src={this._previewDesktop} alt={`${this.props.node.frontmatter.title} desktop screenshot`} />
                        </div>
                    }

                    { this._previewVideo !== null && 
                        <div className={`shadow`}>
                            <video autoPlay loop muted>
                                <source src={`${this._previewVideo}.webm`}/>
                                <source src={`${this._previewVideo}.mp4`}/>
                                Unfortunately, your browser doesn't support embedded videos.
                            </video>
                        </div>
                    }
                </div>
            </article>
    )}
}