import React, {Component} from "react"
import style from "./waves-animation.module.scss"

type WavesProps = typeof WavesAnimation.defaultProps & {
    color1: string,
    color2: string,
    minHeight?: number,
    maxHeight?: number,
    fromTop?: number
}

export default class WavesAnimation extends React.Component<WavesProps> {
    static defaultProps = {
        minHeight: 50,
        maxHeight: 50,
        fromTop: 60
    }
    render() {
        return (
            <div className={`h-full`}>
                <svg 
                    className={`absolute w-full h-full`} 
                    style={{ minHeight: this.props.minHeight, maxHeight: this.props.maxHeight, top:`${this.props.fromTop}%` }} 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" 
                    shapeRendering="auto">
                    <defs>
                     <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className={style.parallax}>
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        {/* <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /> */}
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill={this.props.color2} />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill={this.props.color1} />
                    </g>
                </svg>
                <div className={`absolute w-full bottom-0`} style={{height: `calc(${100 - this.props.fromTop}% - ${this.props.maxHeight}px)`, background: this.props.color1 }}></div>
            </div>
        )
    }
}