import React, {Component, useEffect, useState} from "react";
import Layout from "../layout";
import IphoneGraphic from "../iphone-graphic/iphone-graphic"
import IpadGraphic from "../ipad-graphic/ipad-graphic";
import heroStyles from "./hero.module.scss";
import {HeroStates} from "./hero.interface.module";
import {theme} from "../../../tailwind.config.js";
import Button from "../button/button";

type HeroProps = { 
    title: string,
    subtitle: string
    distance?: number | undefined
    isScrollingUp?: boolean
};

type HeroState = { currentState: HeroStates,
                    distance: number | undefined};


export default class Hero extends React.Component<HeroProps, HeroState> {
    constructor(props:HeroProps){
        super(props);
        this.state = {
            currentState: HeroStates.start,
            distance: undefined
        }
    }

    componentWillReceiveProps(){
        this.setState({distance: this.props.distance})
    }

    handleClick = (e:Event, newState:HeroStates) => {
        this.activateMode(newState);
    }

    activateMode = (newState:HeroStates) => {
        this.setState({ currentState: newState })
    }


    render() {
      return (
        <div style={{'background':`linear-gradient(0deg, ${theme.extend.colors.darktertiary}, ${theme.extend.colors.tertiary})`, 'height':`${ !this.props.distance || (this.props.distance < .8 && !this.props.isScrollingUp) ? '150vh' : '300vh'}`}}
                 className={`overflow-hidden transition-height duration-1000`}>
            <div className={`${heroStyles.wrapper} container lg:mx-auto relative`}>

                <div className={`fixed w-full z-10 ${heroStyles.safariZindexFix}`} style={{top:'5%'}}>
                    <Layout>
                        <div className={`transition duration-300 ease-in-out ${this.state.currentState === HeroStates.hidden ? 'fadeUp' : ''}`} style={{'bottom':'20%', 'zIndex':2}}>
                            <div 
                                className={`-ml-4 px-4 py-10 md:-ml-10 md:px-10 bg-white bg-opacity-75 transition-opacity duration-150`} 
                                style={ this.props.distance === undefined || this.props.distance > .20  ?  {} : {opacity: 0}}
                                
                            >
                                <div className={`border-l-8 border-primary pl-8`} >
                                    <h1 
                                        className={`text-5xl md:text-6xl font-sans text-black1 font-extrabold`}
                                    >
                                        {this.props.title}
                                    </h1>
                                    <h2 className={`text-xl md:text-2xl font-semibold text-black1 opacity-75`}>
                                        {this.props.subtitle}
                                    </h2>

                                </div>
                            </div>
                            <div className={`mt-8 flex transition duration-500 md:duration-300 ease-out`}
                                 style={ this.props.distance === undefined || this.props.distance > .20 ? {} : {transform:`translateY(100vh)`} }>
                                <div className="mr-4 mb-2">
                                    <Button 
                                        text="About me"
                                        url="#about-me"
                                        appearance="primary"
                                    > 
                                    </Button>
                                </div>
                                <div className="mr-4 mb-2">
                                    <Button 
                                        text="Projects"
                                        url="#projects"
                                        appearance="primary"
                                    >
                                    </Button>
                                </div>
                                <div>
                                    <Button 
                                        text="Contact"
                                        url="#contact"
                                        appearance="primary"
                                    >
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Layout>
                </div>
                <div>
                    <IpadGraphic 
                        scrollWatch 
                        distance={this.props.distance} 
                        isScrollingUp={this.props.isScrollingUp}
                    ></IpadGraphic>
                    <IphoneGraphic 
                        scrollWatch 
                        distance={this.props.distance} 
                        isScrollingUp={this.props.isScrollingUp}
                    ></IphoneGraphic> 
                </div>
            </div>
            
        </div>
      );
    }
  }