import React, {Component} from "react"
import WavesAnimation from "../waves-animation/waves-animation";
import ThemeColors from "../../../tailwind.config.js";

type IpadGraphicProps = { 
    distance ?: number
    interactive ?: boolean
    isScrollingUp ?: boolean
    scrollWatch ?: boolean
};

type IpadGraphicState = {
    isPresenting ?: boolean
    distance ?: number
}
  
export default class IpadGraphic extends React.Component<IpadGraphicProps, IpadGraphicState> {
    constructor(props:IpadGraphicProps){
        super(props);
        this.state = {
            distance:  this.props.distance,
            isPresenting: false
        }
    }

    private _isMoved = false;
    private _transformStyle = {};

    componentWillReceiveProps(){
        if(!this.props.scrollWatch || this.props.distance === undefined) {
            return;
        }
        if (this.props.distance < 0.8 && !this.props.isScrollingUp) {
            this._isMoved = true;
        } 
        
        if (this.props.distance > 0.1 && this.props.isScrollingUp) {
            this._isMoved = false;
        }
    }

    activatePresentMode() {
        if(this.props.interactive) {
            this.setState({ isPresenting: !this.state.isPresenting}); //toggle
        }
    }

    render() {
        return (
            <div id={`ipad-graphic`} >
                <div 
                    className={`
                        device 
                        ${ this.props.scrollWatch ? 
                            this._isMoved ? 'rotateIn' : 'rotateOut'
                            :
                            ''
                        } 
                        ${ this.state.isPresenting ? 'present' : ''}
                        ${ this.props.interactive ? 'cursor-pointer interactive' : ''}
                    `
                } 
                    style={this._transformStyle} 
                    onClick={(e) => {this.activatePresentMode()}}
                    >
                    <div className={`shadow`}></div>
                    <div className={`side`}></div>
                    {/* <div className={`toggler`}>
                        <div></div>
                    </div> */}
                    <div className={`lightning`}></div>
                    <div className={`bottomspeaker`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`bottomspeaker mod-right`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {/* <div className={`screws`}>
                        <div></div>
                        <div></div>
                    </div> */}
                    <div className={`volume`}>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`front`}>
                        <div className={`frontCover`}></div>
                        <div className={`camera`}>
                            <div></div>
                        </div>
                        {/* <div className={`speaker`}></div> */}
                        <div 
                             className={`screen bg-gradient-to-t from-black1 to-black`}>
                            {/* <div className={`circle`}></div>
                            <div className={`bottom bg-white`}></div> */}
                            { !this.props.children &&
                                <WavesAnimation 
                                    color1={ThemeColors.theme.extend.colors.primary} 
                                    color2={ThemeColors.theme.extend.colors.secondary}
                                    minHeight={75}
                                    maxHeight={100}
                                    fromTop={35}
                                    ></WavesAnimation>
                            }
                            { this.props.children }
                            <div className={`battery`}>
                                <div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div className={`home`}>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}