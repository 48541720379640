import React, {Component} from "react"
import style from "./scroll-indicator.module.scss"

const ScrollIndicator = () => 
  <div>
    <div className={style.arrows}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

export default ScrollIndicator;