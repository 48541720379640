import React from "react"

export default function Layout({ children }) {
  return (
    <>
      <div className="container mx-auto px-4 md:px-8 pb-32">
        {children}
      </div>
    </>
  )
}