module.exports = {
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
  purge: ["./src/**/*.js", "./src/**/*.jsx", "./src/**/*.ts", "./src/**/*.tsx"],
  theme: {
    fontFamily: {
      'sans': ["Nunito Sans", "-apple-system", "BlinkMacSystemFont", "Arial", "San Francisco", "Helvetica Neue", "Helvetica", "Ubuntu", "Roboto", "Noto", "Segoe UI", "sans-serif"]
    },
    extend: {
      colors: {
        primary: '#FDC014',  //'#FFF461', //'#fff461', #F7971E
        secondary: '#2B8088', //#A64253
        tertiary: '#E5D199', //'#2A2867',
        darktertiary: '#292B15',//'#000034',
        sand: '#CAA555',
        black1: '#2F2F2F',
        grey: '#737373',
        white1: '#FCFCFC',
        white2: '#F7F7F7',
      },
      transitionProperty: {
        'height': 'height'
      }
    },
  },
  variants: {},
  plugins: [],
}
