import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Post from "../components/post"
import Scroller from "../components/scroller/scroller"
import ImageWrapper from "../components/image-wrapper/image-wrapper"
import Timeline from "../components/timeline/timeline"
import SkillItem from "../components/skill-item/skill-item"

import HeadshotImg from "../assets/headshot.jpg"
import ImgSass from "../assets/identifiers/identifier-sass.svg"
import ImgHTML from "../assets/identifiers/identifier-html5.svg"
import ImgAngular from "../assets/identifiers/identifier-angular.svg"
import ImgGit from "../assets/identifiers/identifier-git.png"
import ImgJS from "../assets/identifiers/identifier-js.svg"
import ImgNode from "../assets/identifiers/identifier-node.svg"
import ImgReact from "../assets/identifiers/identifier-react.svg"
import ImgRiot from "../assets/identifiers/identifier-riotjs.svg"
import ImgStencil from "../assets/identifiers/identifier-stencil.png"
import ImgThymeleaf from "../assets/identifiers/identifier-thymeleaf.jpg"
import ImgTypescript from "../assets/identifiers/identifier-typescript.jpg"

import IconWireframe from "../assets/identifiers/icon-wireframe.svg"
import IconPrototyping from "../assets/identifiers/icon-prototype.svg"
import IconResponsive from "../assets/identifiers/icon-responsive.svg"
import IconMotion from "../assets/identifiers/icon-animation.svg"
import SEO from "../components/seo/seo"
import Button from "../components/button/button"



export default function Home({ data }) {
  return (
    <>
    <SEO></SEO>
    <Scroller>
        <Layout>
          <div className={`relative z-10`}>
            <div className={`grid grid-cols-12 gap-4 pb-16 bg-white shadow-lg border-1 border-grey`}>
              <div className={`col-span-12`}>
                <h2 id="about-me" className={`font-bold text-4xl text-center pt-24 mb-12 mod-underline secondary`}>About me</h2>
              </div>
              <div className={`col-start-2 col-span-10 md:col-span-5 md:col-start-2`}>
                <h3 className={`text-2xl font-serif font-semibold mb-8`}>My experience</h3>
                <div dangerouslySetInnerHTML={{ __html: data.aboutMd.edges[0].node.html }}></div>
              </div>
              <div className={`col-start-2 col-span-10 md:col-end-12 md:col-span-4`}>
                <ImageWrapper appearance="headshot" url={HeadshotImg}></ImageWrapper>
              </div>
              <div className={`col-start-2 col-span-10 mb-8`}>
                <h4 className={`text-sm font-bold mb-8 text-grey`}>TEXAS INSTRUMENTS</h4>
                <Timeline nodes='{
                                  "nodes": [
                                    {"title":"UI/UX Designer", "date":"April 2015"},
                                    {"title":"Front End Designer", "date":"April 2019 - 2021"},
                                    {"title":"Front End Specialist", "date":"April 2021 - Present"}
                                  ]
                                }'></Timeline>
              </div>
              <div className={`col-start-2 col-span-10 mb-16`}>
                <div dangerouslySetInnerHTML={{ __html: data.proficienciesMd.edges[0].node.html }}></div>
              </div>
              <div className={`col-start-2 col-span-10`}>
                  <h3 className={`text-2xl font-semibold mb-6`}>Proficiences</h3>
                  <h4 className={`text-xs uppercase font-bold`}>CORE SKILLS</h4>
              </div>
              <div className={`col-start-2 col-span-2`}>
                  <SkillItem imgUrl={ImgSass} title="SASS/CSS"></SkillItem>
              </div>
              <div className={`col-start-5 md:col-start-4 col-span-2`}>
                  <SkillItem imgUrl={ImgHTML} title="HTML5"></SkillItem>
              </div>
              <div className={`col-start-8 md:col-start-6 col-span-2`}>
                  <SkillItem imgUrl={ImgJS} title="Javascript"></SkillItem>
              </div>
              <div className={`col-start-2 col-span-10 mt-4`}>
                  <h4 className={`text-xs uppercase font-bold`}>TOOL-SET</h4>
              </div>
              <div className={`col-start-2 col-span-2`}>
                  <SkillItem imgUrl={ImgNode} title="Node.js"></SkillItem>
              </div>
              <div className={`col-start-5 md:col-start-4 col-span-2`}>
                  <SkillItem imgUrl={ImgGit} title="Git"></SkillItem>
              </div>
              <div className={`col-start-8 md:col-start-6 col-span-2`}>
                  <SkillItem imgUrl={ImgStencil} title="StencilJS"></SkillItem>
              </div>
              <div className={`col-start-2 md:col-start-8 col-span-2`}>
                  <SkillItem imgUrl={ImgAngular} title="Angular"></SkillItem>
              </div>
              <div className={`col-start-5 md:col-start-10 col-span-2`}>
                  <SkillItem imgUrl={ImgThymeleaf} title="Thymeleaf"></SkillItem>
              </div>
              <div className={`col-start-8 md:col-start-2 col-span-2`}>
                  <SkillItem imgUrl={ImgTypescript} title="Typescript"></SkillItem>
              </div>
              <div className={`col-start-2 md:col-start-4 col-span-2`}>
                  <SkillItem imgUrl={ImgRiot} title="RiotJS"></SkillItem>
              </div>
              <div className={`col-start-5 md:col-start-6 col-span-2`}>
                  <SkillItem imgUrl={ImgReact} title="React"></SkillItem>
              </div>
              <div className={`col-start-2 col-span-10 mt-4`}>
                  <h4 className={`text-xs uppercase font-bold`}>UI/UX</h4>
              </div>
              <div className={`md:col-start-2 col-start-2 col-span-2`}>
                  <SkillItem appearance="secondary" imgUrl={IconWireframe} title="Wireframing"></SkillItem>
              </div>
              <div className={`md:col-start-4 md:col-span-2 col-start-5 col-span-6`}>
                  <SkillItem appearance="secondary" imgUrl={IconResponsive} title="Responsive Design"></SkillItem>
              </div>

              <div className={`md:col-start-6 col-start-2 col-span-2`}>
                  <SkillItem appearance="secondary" imgUrl={IconPrototyping} title="Prototyping"></SkillItem>
              </div>
              <div className={`md:col-start-8 col-start-5 col-span-6`}>
                  <SkillItem appearance="secondary" imgUrl={IconMotion} title="Motion design"></SkillItem>
              </div>
            </div>
          </div>
          <div className={`absolute w-full left-0 bg-white2 shadow-lg`} style={{height:'100%', transform:'translateY(-75%)'}}></div>
        </Layout>
    </Scroller>
    <section className={`bg-white2 relative overflow-hidden`}>
      <Layout>
        <h2 id="projects" className={`font-bold text-4xl font-sans text-center pt-32  mb-12 mod-underline`}>Projects</h2>
        <div className={`py-8`}>
            {data.allPosts.edges.map(({ node }) => (
                <Post
                  key={node.id}
                  node={node}
                ></Post>
            ))}
        </div>
      </Layout>
      
    </section>
    <section className={`relative h-screen`}>
        <Layout>
                <div className={`p-16 bg-white shadow -mt-40` }>
                  <div className={`grid grid-cols-12 gap-4 pb-4 bg-white`}>
                    <div className={`col-span-12`}>
                      <h2 id="contact" className={`font-bold text-4xl font-sans text-center mb-12 mod-underline secondary`}>Contact me</h2>
                    </div>
                    <div className={`col-span-12 md:col-span-4 md:col-start-2`}>
                      <h3 className={`text-lg mb-2`}>Samuel Li</h3>
                      <div className={`mb-1 font-bold text-xs uppercase`}>UI/UX Designer and Front End Developer</div>
                      <div>Toronto, Ontario</div>
                      <div className={`mt-4`}>hello@samuelli.ca</div>
                    </div>
                    <div className={`col-span-12 md:col-span-6 md:col-start-6`}>                    
                      <p>A curious developer and designer who loves holistic and strategic approaches to code and design.  Reach out to chat and see if we can partner together.</p>
                    </div>
                    <div className={`col-span-12 md:col-start-2 flex flex-wrap mt-8`}>
                      <div className={`mr-4 mb-2`}>
                      <Button appearance="tertiary" url="mailto: hello@samuelli.ca" text="Send email"></Button>
                      </div>
                      <div className={`mr-4 mb-2`}>
                        <Button appearance="tertiary" url="https://www.linkedin.com/in/samuel-li-5b4aba80/" text="LinkedIn"></Button>
                      </div>
                      <div>
                        <Button appearance="tertiary" url="https://bitbucket.org/forTheKing/portfolio-custom-samuelli/src/master/" text="Portfolio code"></Button>
                      </div>
                    </div>
                  </div>
                </div>
          </Layout>
    </section> 
    </>
  )
}

export const query = graphql`
  query MyQuery {
    aboutMd: allMarkdownRemark(filter: {frontmatter: { title: { eq: "About me" } } } ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
    proficienciesMd: allMarkdownRemark(filter: {frontmatter: { title: { eq: "Proficiencies" } } } ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
    allPosts: allMarkdownRemark(filter: {frontmatter: {aboutsection: {ne: true}}}, sort: { fields: [frontmatter___order], order: ASC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            codeUrl
            url
            tagsDev
            tagsUI
            previewPhone
            previewTablet
            previewDesktop
            previewVideo
            subtitle
            summary
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`