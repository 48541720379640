import React, {Component} from "react"
import skillItemStyle from "./skill-item.module.scss"

type SkillItemProps = { 
  title ?: string,
  imgUrl: string,
  appearance ?: string
};

export default class SkillItem extends React.Component<SkillItemProps> {
    render() {
        return (
        <div>
            <div className={`text-sm text-grey font-bold`}>{this.props.title}</div>
            <div className={`${skillItemStyle.skillIcon} ${skillItemStyle[this.props.appearance]}`} style={{backgroundImage:`url(${this.props.imgUrl})`}}>
            </div>
        </div>
        );
    }
}