import React, {Component} from "react"
import {ImageWrapperAppearances} from "./image-wrapper.interface.module";
import ImageStyle from "./image-wrapper.module.scss"

type ImageWrapperProps = { 
  appearance: string,
  url: string
};

export default class Label extends React.Component<ImageWrapperProps> {
  render() {
    return (
      <>
        <div className={`relative`}>
          { this.props.appearance === ImageWrapperAppearances.Headshot && 
            <>
              <div className={`absolute bg-secondary rounded-full right-0`} style={{height:"33%", width: "33%"}}></div>
              <div className={`absolute bg-primary rounded-full bottom-0`} style={{height:"80%", width: "80%"}} > </div> 
            </>
          }
          <img src={this.props.url} className={`relative pb-8 pl-8 ${this.props.appearance === ImageWrapperAppearances.Headshot ? ImageStyle.headshot : ''}`}/>
        </div>
      </>
    );
  }
}