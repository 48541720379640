import React, {Component} from "react";
import WavesAnimation from "../waves-animation/waves-animation";
import ThemeColors from "../../../tailwind.config.js";
import { HeroStates } from "../hero/hero.interface.module";

type IphoneGraphicProps = { 
    currentState ?: HeroStates,
    distance ?: number
    interactive ?: boolean
    isScrollingUp ?: boolean
    scrollWatch ?: boolean
};

type IphoneGraphicState = {
    isPresenting?: boolean;
    distance ?: number;
}
  
export default class IphoneGraphic extends React.Component<IphoneGraphicProps, IphoneGraphicState> {
    constructor(props:IphoneGraphicProps){
        super(props);
        this.state = {
            distance: this.props.distance,
            isPresenting: false
        }
    }

    private _isMoved:Boolean = false;
    private _transformStyle = {};

    componentWillReceiveProps(){
        // this.setState({distance: this.props.distance})
        // this._transformStyle = {
        //     transform: `rotateX(50deg) rotateZ(${-46 + (5 - (this.props.distance * 5))}deg) translate(0px, ${137 + (this.props.distance * 5)}px)`
        // }
        // If scrolling down and window is above the scroll container by threshold
        if(!this.props.scrollWatch || this.props.distance === undefined) {
            return;
        }

        if (this.props.distance < 0.8 && !this.props.isScrollingUp) {
            this._isMoved = true;
        } 
        
        if (this.props.distance > 0.2 && this.props.isScrollingUp) {
            this._isMoved = false;
        }
    }

    activatePresentMode() {
        if(this.props.interactive) {
            this.setState({ isPresenting: !this.state.isPresenting}); //toggle
        }
    }

    render() {
        return (
            <div id={`phone-graphic`}>
                <div 
                    className={
                        `iphone 
                        ${ this.props.scrollWatch ?
                            this._isMoved ? 'rotateIn' : 'rotateOut'
                            :
                            ''
                        } 
                        ${this.state.isPresenting ? 'present' : ''}
                        ${this.props.interactive ? 'cursor-pointer interactive' : ''}
                        `
                    }
                    style={this._transformStyle}
                    onClick={(e) => {this.activatePresentMode()}}
                    >
                    <div className={`shadow`}></div>
                    <div className={`side`}></div>
                    <div className={`toggler`}>
                    <div></div>
                    </div>
                    <div className={`lightning`}></div>
                    <div className={`bottomspeaker`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`screws`}>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`volume`}>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={`front`}>
                        <div className={`frontCover`}></div>
                        <div className={`camera`}>
                            <div></div>
                        </div>
                        <div className={`speaker`}></div>
                        <div 
                             className={`screen bg-gradient-to-t from-black1 to-black`}>
                            {/* <div className={`circle`}></div> */}
                            <div>
                                { !this.props.children &&
                                    <WavesAnimation 
                                        color1={ThemeColors.theme.extend.colors.secondary} 
                                        color2={ThemeColors.theme.extend.colors.primary}
                                    ></WavesAnimation>
                                }
                                {this.props.children }
                            </div>
                            <div className={`bottom bg-white`}></div>
                            <div className={`battery`}>
                                <div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div className={`home`}>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}